<template>
    <div>
        <nav class="w-full mt-4 mb-4 border-b border-neutral-300 overflow-x-scroll md:overflow-visible">
            <ul class="flex w-full items-end space-x-8 sm:space-x-10 box-border">
                <li class="relative translate-y-[1px]" v-for="(tab, i) in tabs">
                    <div v-if="tabIsActive(i)" class="w-0 h-0 
                        absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full
                        border-l-[10px] border-l-transparent
                        border-t-[10px] border-t-red-600
                        border-r-[10px] border-r-transparent">
                    </div>
                    <div :class="{ 'border-b border-neutral-300 text-neutral-600 hover:text-neutral-800': !tabIsActive(i), 'border-b-2 border-red-600 text-red-700': tabIsActive(i) }" class="font-headline text-lg sm:text-xl uppercase cursor-pointer text-nowrap " v-text="tab.label" @click="setActiveTab(i)">
                    </div>
                </li>
            </ul>
        </nav>

        <template v-for="(tab, i)  in tabs">
            <div class="grid grid-cols-1 sm:grid-cols-4 sm:gap-16 p-3 sm:p-8 bg-neutral-50 user-content rounded" v-if="tabIsActive(i)">
            <div :class="{'col-span-3' : tab.display_lists, 'col-span-4' : ! tab.display_lists}">
                    <h5 v-html="tab.headline"></h5>
                    <div v-html="tab.content"></div>

                </div>
                <div class="col-span-1" v-if="tab.display_lists">
                        <div v-for="list in tab.lists">
                        <h5 v-html="list.headline"></h5>
                        <ul>
                            <li v-for="item in list.items" v-text="item.value"></li>
                        </ul>
                        </div>
                    </div>
            </div>
        </template>
    </div>
</template>
<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
    tabs: {
        type: Array,
        required: true
    },
});

const activeTab = ref(0);

function tabIsActive(i) {
    return i == activeTab.value;
}

function setActiveTab(i) {
    activeTab.value = i;
}

</script>