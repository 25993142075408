<template>
    <div class="flex justify-between items-center gap-x-3">
        <div class="user-content">
            <h2 class="h2">{{ title }}</h2>
            <p class="!mt-0">{{ description }}</p>
        </div>

        <div>
            <template v-if="success">
                <div v-html="success"></div>
            </template>
            <template v-else>
                <div v-if="error" class="text-red-500">{{ error }}</div>

                <form method="post" action @submit.prevent="submit">
                    <div class="flex items-center gap-2">
                        <template v-for="field in fields">
                            <input v-if="field.type == 'honeypot'" type="text" v-model="values['input_' + field.id]" class="sr-only" />
                            <div v-else class="flex-grow">
                                <input v-bind="fieldAttrs(field)" v-model="values['input_' + field.id]" :disabled="loading" class="py-2 px-4 text-neutral-600 rounded bg-white ring-1 ring-neutral-300" />
                            </div>
                        </template>

                        <button type="submit" class="flex items-center gap-4 px-4 py-2 bg-blue-500 text-white rounded-lg" :disabled="loading">
                            <div v-if="loading"><LoadingIcon class="w-4 h-4 mr-2" /></div>
                            <span>Submit</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" /></svg>
                        </button>
                    </div>
                </form>
            </template>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import LoadingIcon from './LoadingIcon.vue';
import axios from 'axios';

const props = defineProps({ form: { type: Object, required: true } });

const values = ref({});

const fields = ref(props.form.fields);
const title = ref(props.form.title);
const description = ref(props.form.description);

const loading = ref(false);
const error = ref(false);
const success = ref(false);

function fieldAttrs(field) {
    let attrs = {
        type: field.type,
        label: field.label,
        required: field.isRequired,
        placeholder: field.placeholder,
    };

    if (field.autocompleteAttribute) {
        attrs.autocomplete = field.autocompleteAttribute;
    }

    return attrs;
}

/**
 * Submit the form data to the REST api endpoint.
 */
function submit() {
    loading.value = true;
    clearErrors();

    axios
        .post('/wp-json/lab/v1/email-capture', values.value)
        .then(({ data }) => handleResponse(data))
        .catch((err) => handleUnknownError(err))
        .then(() => (loading.value = false));
}

/**
 * Handles a non-error response.
 *
 * @param {Object} data
 */
function handleResponse(data) {
    if (!data.is_valid) {
        if (data.validation_messages) {
            error.value = 'Please see the highlighted errors below:';
            handleValidationErrors(data.validation_messages);
        } else {
            handleUnknownError(data);
        }
    } else {
        success.value = data.confirmation_message;
    }
}

/**
 * Clear all errors.
 */
function clearErrors() {
    error.value = false;
    fields.value.forEach((field) => (field.error = null));
}

/**
 * Add an error to an individual fields.
 *
 * @param {Number} id
 * @param {String} message
 */
function addFieldError(id, message) {
    const field = fields.value.findIndex((f) => f.id == id);

    fields.value[field].error = message;
}

/**
 * Handle validation-level errors.
 *
 * @param {Object} errors
 */
function handleValidationErrors(errors) {
    Object.keys(errors).forEach((key) => addFieldError(key, errors[key]));
}

/**
 * Handle an unexpected error.
 *
 * @param {*} error
 */
function handleUnknownError(error) {
    error.value = 'An unknown error has occurred.';
}
</script>
